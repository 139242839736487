// import Link from 'next/link';
import classNames from 'classnames';
import Link from 'next/link';
import { useLocale } from 'next-intl';

import { socialsConfig } from './SocialsConfig';

import { SocialsT } from 'types/socials';

interface Props {
  names: SocialsT[];
  size?: Size;
}

type Size = 'small' | 'medium' | 'large';

export default function Socials({ names, size = 'medium' }: Props) {
  const locale = useLocale();

  const selectedSocials = names.filter(
    (name: string) => socialsConfig[name]?.links[locale] !== undefined,
  );

  const containerClasses = classNames('scale-100 rounded-full bg-white transition-all duration-300 ease-in-out hover:-translate-y-1 hover:scale-[1.1]', {
    'p-2': size === 'small',
    'p-4': size === 'medium',
    'p-6': size === 'large',
  });

  const iconClasses = classNames('text-premium-dark', {
    'text-md': size === 'small',
    'text-xl': size === 'medium',
    'text-2xl': size === 'large',
  });

  return (
    <>
      {selectedSocials.map((socialName) => {
        const Component = socialsConfig[socialName]?.icon;
        return (
          <Link
            rel="nofollow noopener noreferrer"
            href={socialsConfig[socialName]?.links[locale] ?? '/'}
            target={socialsConfig[socialName]?.target ?? '_blank'}
            key={'socialName' + socialName}
            aria-label={`Link to ${socialName}`}
          >
            <div
              key={'social.' + socialName}
              className={containerClasses}
            >
              <Component className={iconClasses} />
            </div>
          </Link>
        );
      })}
    </>
  );
}
