'use client'
import cn from 'classnames';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import HeaderExpendedMenu from './atoms/HeaderExpendedMenu';

import { LanguageSelector } from '@components/Layout/LanguageSelector';
import { useBodyScroll } from '@context/BodyScrollContext';
import { Link } from '@lib/navigation';
import { Promotion } from '@utils/api/server/wr/options';
import CloseSVG from 'public/static/svg/close.svg';
import HamburgerSVG from 'public/static/svg/hamburger.svg';

export interface HeaderProps {
  className?: string;
  promotions: Promotion[];
  children?: React.ReactNode;
}

export default function MobileHeader({
  className,
  promotions,
  children
}: HeaderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { disableScroll, enableScroll } = useBodyScroll();

  const toggleOpen = () => {
    setIsOpen((wasOpen) => !wasOpen);
    setTimeout(() => {
      if (isOpen) {
        enableScroll();
      } else {
        disableScroll();
      }
    }, 100);
  }

  const close = () => {
    setIsOpen(false);
    enableScroll();
  }

  return (
    <div className={cn(
      'bg-white flex flex-col w-100 text-premium relative dark:bg-premium-darker dark:text-white',
      className,
      {
        'h-[100dvh]': isOpen,
      }
    )}
    >
      {children}
      <div className={classNames('z-10 w-100 text-premium relative flex min-h-16 items-center justify-between px-3 lg:container', {
        'bg-premium-dark dark:bg-premium-darker': isOpen,
        'bg-white dark:bg-premium-darker': !isOpen,
      })}>
        <div className="flex w-max items-center justify-between gap-3 py-3">
          <Link onClick={close} href="/" className='flex'>
            <Image
              className={classNames({
                'hidden': isOpen,
                'dark:hidden block': !isOpen,
              })}
              src="/static/svg/transinfoLogoColor.svg"
              alt="TransInfo"
              width={150}
              height={37.5}
            />
            <Image
              className={classNames('dark:block', {
                'block': isOpen,
                'hidden': !isOpen,
              })}
              src="/static/svg/transinfoLogoWhiteColor.svg"
              alt="TransInfo"
              width={150}
              height={37.5}
            />
          </Link>
        </div>
        <div className='flex'>
          {isOpen &&
            <>
              <LanguageSelector
                className='mr-3'
                compact
                isMobile
              />
              <Link
                className={'flex mr-3 h-10 w-10 items-center justify-center border border-white rounded-full bg-transparent text-white'}
                href="/search"
                onClick={toggleOpen}
              >
                <span className="block w-4">
                  <BiSearch />
                </span>
              </Link>
            </>
          }
          <button
            className={classNames('flex h-10 w-10 items-center justify-center rounded-full',
              {
                'bg-premium-dark text-white': !isOpen,
                'bg-white text-premium-dark': isOpen,

              })}
            onClick={toggleOpen}
          >
            <span className="block w-4">

              {isOpen ? <CloseSVG /> : <HamburgerSVG />}
            </span>
          </button>
        </div>
      </div>
      <div className={classNames('duration-300 transition-all w-full grow relative bg-premium-dark dark:bg-premium-darker dark:text-white flex flex-col lg:hidden overflow-auto',
        {
          'opacity-0 pointer-events-none h-0': !isOpen,
        },
        {
          'opacity-1 h-max grow': isOpen,

        })}>
        <HeaderExpendedMenu closeMenu={toggleOpen} promotions={promotions} />
      </div>
    </div>
  );
}
