import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';

import FooterLogin from '../../Footer/atoms/FooterLogin';

import DarkModeSwitch from '@components/Layout/DarkMode/DarkModeSwitch';
import Socials from '@components/Socials/Socials';
import { Link } from '@lib/navigation';
import { Promotion } from '@utils/api/server/wr/options';
import { link } from '@utils/links';
import { MenuItem, getMenuItems, categories, MenuItemCategory } from '@utils/menu';
import { TOS } from 'enums/legalDocuments';

const HeaderLink = ({ t, item, horizontal, closeMenu, itemClass, nofollow }: { t?: (arg: string) => string, item: MenuItem, horizontal?: boolean, closeMenu: () => void, itemClass?: string, nofollow?: boolean }) => {
  const translate = useTranslations('menu');
  if (item.Component) {
    return <item.Component />;
  }
  if (!t) {
    t = translate;
  }
  const seo = nofollow ? "nofollow" : "";

  return (
    <Link
      href={link(t(item.link))}
      onClick={closeMenu}
      className={itemClass ? itemClass : cn('block w-full', item.classNames, item.color ? `text-${item.color}` : '', {
        'mb-3 pl-2': !horizontal,
        'text-center [&:not(:first-child)]:border-l': horizontal,
      })}
      rel={seo}
    >
      {item.icon && <item.icon />}
      {t(item.label)}
    </Link>
  );
}

function HeaderExpendedMenu({ closeMenu, promotions }) {
  const t = useTranslations('menu');
  const locale = useLocale();

  const specialTOSes = (TOS[locale]?.other || [])
    .filter((tos) => tos.showFrom < Date.now() && tos.showUntil > Date.now());

  const langVisibilityFilter = (item: MenuItem) => item.visibleInLang.includes(locale);
  const categoryFilter = (category: MenuItemCategory) => (item: MenuItem) => item.category === category;
  const isMobileFilter = (item: MenuItem) => item.onMobile;

  const menus = {} as Record<MenuItemCategory, MenuItem[]>;

  categories.forEach((category) => {
    menus[category] = getMenuItems(categoryFilter(category), langVisibilityFilter, isMobileFilter);
  });

  const menuItemsTabs = menus.regular;

  const middleIndex = Math.ceil(menuItemsTabs.length / 2);

  const firstHalf = menuItemsTabs.splice(0, middleIndex);
  const secondHalf = menuItemsTabs.splice(-middleIndex);

  const identity = (arg: any) => arg;

  return (
    <nav className="overflow-auto text-[14px] text-white flex flex-col min-h-[calc(100vh-104px)]">
      <div className="grow flex flex-col">
        <div className="px-8 py-4 flex w-full grow justify-around font-bold text-center">
          <div className="flex flex-col justify-center">
            {firstHalf.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
          <div className="my-2 block min-h-full w-0.5 border-l border-premium-900" />
          <div className="flex flex-col justify-center">
            {secondHalf.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
        </div>
        {menus.mid.length > 0 && (
          <div className='px-8 py-4 grow flex flex-col justify-center text-center font-bold'>
            {promotions.map((promo: Promotion) => (
              <HeaderLink t={identity} closeMenu={closeMenu} key={`footer-menu-${promo.link}`} nofollow={true} item={{
                label: promo.label,
                visibleInLang: [],
                category: 'mid',
                onDesktop: true,
                onMobile: true,
                link: promo.link,
              }} />
            ))}
            {specialTOSes.map((tos) => (
              <HeaderLink t={identity} closeMenu={closeMenu} key={`footer-menu-${tos.slug}`} nofollow={true} item={{
                label: tos.title,
                visibleInLang: ['pl'],
                category: 'mid',
                onDesktop: true,
                onMobile: true,
                link: `/rules/${tos.slug}`,
              }} />
            ))}
            {menus.mid.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
        )}
        {menus.advertise.length > 0 && (
          <div className='grow py-2 text-center flex flex-col justify-center bg-premium-medium dark:bg-premium-dark'>
            {menus.advertise.length > 1 && (
              <h6 className="mb-1 text-[14px] py-1 text-center">{t('advertise')}:</h6>
            )}
            <div className='px-8 flex flex-row items-center font-bold'>
              {menus.advertise.map((item) => (
                <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} nofollow={true} item={item} horizontal />
              ))}
            </div>
          </div>
        )}
        <div className="py-4 flex flex-wrap gap-x-2 grow w-full items-center justify-evenly px-2">
          <div className='flex flex-row gap-x-2'>
            <Socials names={['yt', 'fb', 'tt', 'li']} size='small' />
          </div>
          <FooterLogin labels={{ logIn: t('logIn'), logOut: t('logout') }} menuClose={closeMenu} />
          <DarkModeSwitch
            mobile
          />
        </div>
        {menus.footer.length > 0 && (
          <div className='px-8 pt-2 pb-8 grow flex flex-col justify-center text-center text-[14px]'>
            {menus.footer.map((item) => (
              <HeaderLink itemClass='block pb-1' closeMenu={closeMenu} key={`footer-menu-${item.link}`} nofollow={true} item={item} />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}

export default HeaderExpendedMenu;
