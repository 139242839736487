export interface LegalDocumentsEntry {
  next?: string;
  current: string;
  legacyFormat?: boolean;
  previous: {
    date: string;
    legacyFormat?: boolean;
  }[];
  other?: {
    title: string;
    slug: string;
    showFrom: number;
    showUntil: number;
    legacyFormat?: boolean;
  }[];
}

export interface LegalDocumentsMap {
  [key: string]: LegalDocumentsEntry;
}

export const TOS: LegalDocumentsMap = {
  de: {
    current: '2023-10-23',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  en: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  es: {
    current: '2021-03-01',
    legacyFormat: true,
    previous: [],
  },
  hu: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  lt: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  pl: {
    current: '2023-05-25',
    previous: [
      { date: '2023-03-31' },
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
    other: [
      { title: 'Regulamin wiosennej promocji', slug: 'spring-2024', showFrom: 1714122000000, showUntil: 1717019999000, legacyFormat: false }
    ],
  },
  ro: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  ru: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  ua: {
    current: '2023-02-02',
    previous: [],
  },
};

export const POLICY: LegalDocumentsMap = {
  de: {
    current: '2021-03-01',
    legacyFormat: true,
    previous: [],
  },
  en: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  es: {
    current: '2021-03-01',
    legacyFormat: true,
    previous: [],
  },
  hu: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  lt: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  pl: {
    current: '2024-08-12',
    previous: [
      { date: '2023-05-25' },
      { date: '2023-03-31' },
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  ro: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  ru: {
    current: '2023-03-31',
    previous: [
      {
        date: '2021-03-01',
        legacyFormat: true,
      },
    ],
  },
  ua: {
    current: '2023-02-02',
    previous: [],
  },
};
